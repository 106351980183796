import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Intro from "../components/collections/intro"
import NewsListItem from "../components/news/list-item"
import Button from "../components/button/button"
import Container from "../components/container/container"
import Select from "../components/select/select"
import style from "../components/news/news.mod.scss"

const News = ({ data }) => {
  const allPosts = data.allPosts.nodes
  const pagesize = 8

  let years = []
  let categories = []
  for (let i = 0; i < allPosts.length; i++) {
    let post = allPosts[i]
    let d = new Date(post.meta.createdAt)
    let y = d.getFullYear() + ""
    if (years.indexOf(y) === -1) {
      years.push(y)
    }
    let c = post.category.title
    if (categories.indexOf(c) === -1) {
      categories.push(c)
    }
  }

  const [filteredPosts, setFilteredPosts] = useState(allPosts)
  const [list, setList] = useState([...allPosts.slice(0, pagesize - 1)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allPosts.length > pagesize)
  const [topic, setTopic] = useState("")
  const [year, setYear] = useState("")

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < filteredPosts.length
      const nextResults = isMore
        ? filteredPosts.slice(currentLength, currentLength + pagesize)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
      setHasMore(currentLength + nextResults.length < filteredPosts.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore])

  useEffect(() => {
    let newFilteredPosts = allPosts.filter((post) => {
      let r = true
      if (topic && post.category.title !== topic) {
        r = false
      }
      let d = new Date(post.meta.createdAt)
      if (year && d.getFullYear() + "" !== year) {
        r = false
      }
      return r
    })
    setFilteredPosts(newFilteredPosts)
    setList([...newFilteredPosts.slice(0, pagesize - 1)])
    setHasMore(pagesize - 1 < newFilteredPosts.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, year])

  return (
    <Layout seo={data.datoCmsNewsPage.seoMetaTags}>
      <Intro
        title={data.datoCmsNewsPage.title}
        description={data.datoCmsNewsPage.description}
      />
      <Container width="large" gutters pt={2}>
        <Select
          options={[{ value: "", label: "Any topic" }, ...categories]}
          onChange={(e) => {
            setTopic(e.target.value)
          }}
          value={topic}
          className={style.newsSelect}
        />
        <Select
          options={[{ value: "", label: "All years" }, ...years]}
          onChange={(e) => {
            setYear(e.target.value)
          }}
          value={year}
          className={style.newsSelect}
        />
      </Container>

      <Container width="large" gutters pt={1} pb={1}>
        <div className={style.listWrapper}>
          {(!list || list.length === 0) && <p>No articles found</p>}
          {list && list.map((post) => <NewsListItem {...post} key={post.id} />)}
        </div>
      </Container>
      {hasMore && (
        <Container width="large" gutters pt={1} pb={1} textAlign="center">
          <Button onClick={handleLoadMore}>Load more</Button>
        </Container>
      )}
    </Layout>
  )
}

export default News

export const query = graphql`
  {
    datoCmsNewsPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      description
    }
    allPosts: allDatoCmsPost(sort: { fields: meta___createdAt, order: DESC }) {
      nodes {
        id: originalId
        title
        readTime
        slug
        author
        excerpt
        category {
          title
          originalId
        }
        originalId
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "550", fit: "crop", w: "800", q: 60 }
          )
          alt
        }
        meta {
          createdAt
        }
      }
    }
  }
`
