import React from "react"
import style from "./news.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { DateTime } from "luxon"
import Navigator from "../global/navigator"

const NewsListItem = ({
  title,
  excerpt,
  heroImage,
  author,
  readTime,
  meta,
  id,
}) => {
  let dateString =
    meta && meta.createdAt
      ? DateTime.fromISO(meta.createdAt).toFormat("dd.LL.yyyy")
      : ""
  return (
    <article className={style.newsListItem}>
      <Navigator recordId={id}>
        <GatsbyImage
          image={heroImage.gatsbyImageData}
          alt={heroImage.alt ? heroImage.alt : ""}
        />
        <p className={style.date}>{dateString}</p>
        <h3 className={style.title}>{title}</h3>
        <div className={style.excerpt}>{excerpt}</div>
        <footer>
          {author && <span className={style.author}>By {author}</span>}
          {readTime && (
            <span className={style.readtime}>{readTime} min read</span>
          )}
        </footer>
      </Navigator>
    </article>
  )
}

export default NewsListItem
